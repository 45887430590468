import type { AdditionalCampaignData } from ".";

export default {
  campaignId: 6,
  title: "Fund education to end extreme poverty",
  impactUnit: "days",
  posterImg: "/images/projects-carousel/class.png",
  impactTitle: "DAYS OF SCHOOL",
  ctaTitle: "DAYS OF EDUCATION",
  impactLabel: "EDUCATION TO END EXTREME POVERTY",
  dollarImpact: "FUNDS 2 DAYS OF EDUCATION FOR 1 CHILD",
  bgColor: "bg-yellow",
  impactScore: 86,
  icon: "/images/emojis/book.png",
  path: "/campaigns/yellow-rooms",
  shortVideoId: "947560154?h=76f9551275",
  shortVideoIdMobile: "947560177?h=b16b331a51",
  featuredReview: {
    name: "Priti Rao",
    photo: "/images/who-we-are-page/advisors/rao.png",
    title: "Senior Advisor, Dalberg",
    quote:
      "This is one of the most sustainable approaches to poverty alleviation. A small donation has a ripple effect beyond just one child, into a family and into a community.",
  },
} satisfies AdditionalCampaignData;
