import type { AdditionalCampaignData } from ".";

export default {
  campaignId: 5,
  title: "Stop ocean plastic in rivers!",
  impactTitle: "OCEAN-BOUND PLASTIC REMOVED",
  impactUnit: "lbs",
  posterImg: "/images/projects-carousel/catcher.png",
  ctaTitle: "BOTTLES WORTH OF OCEAN-BOUND PLASTIC REMOVED",
  impactLabel: "STOP OCEAN-BOUND PLASTIC POLLUTION",
  dollarImpact: "Removes 2.2 lbs of ocean-bound plastic",
  bgColor: "bg-ocean",
  impactScore: 92,
  icon: "/images/emojis/ocean.png",
  path: "/campaigns/plastic-fischer",
  shortVideoId: "947564310?h=13f7ded16a",
  shortVideoIdMobile: "947565579?h=d2ac678268",
  featuredReview: {
    name: "Chad Frischmann",
    photo: "/images/who-we-are-page/advisors/frischmann.jpg",
    title:
      "Founder, Regenerative Intelligence; former Director, Project Drawdown",
    quote:
      "Plastic Fischer’s approach is the type of win-win-win solution we need to effectively prevent a lot of plastic from entering the ocean in the first place.",
  },
} satisfies AdditionalCampaignData;
