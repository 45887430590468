import oceanVoyages from "./oceanVoyages";
import plasticFischer from "./plasticFischer";
import treesForTheFuture from "./treesForTheFuture";
import yellowRooms from "./yellowRooms";

export const CampaignsData: {
  [key: number]: AdditionalCampaignData;
} = {
  3: treesForTheFuture,
  4: oceanVoyages,
  5: plasticFischer,
  6: yellowRooms,
};

export interface AdditionalCampaignData {
  campaignId: number;
  title: string;
  posterImg: string;
  impactTitle: string;
  ctaTitle: string;
  impactLabel: string;
  impactUnit: string;
  dollarImpact: string;
  bgColor: string;
  impactScore: number;
  icon: string;
  path: string;
  shortVideoId: string;
  shortVideoIdMobile: string;
  featuredReview: {
    name: string;
    photo: string;
    title: string;
    quote: string;
  };
}
