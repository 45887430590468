import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import { CampaignsData } from "./data";
import type { Campaign } from "~/lib/campaigns";

export const campaignQueries = {
  all: () => ["campaigns"],
  list: () =>
    queryOptions({
      queryKey: [...campaignQueries.all(), "list"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<ListResponse>("/v1/campaigns", {
          method: "GET",
        });

        const campaignsData = data.map((campaign) => {
          const additionalData =
            CampaignsData[campaign.id as keyof typeof CampaignsData] ?? {};

          return {
            ...campaign,
            ...additionalData,
          };
        });

        return camelize(campaignsData, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  details: () => [...campaignQueries.all(), "detail"],
  detail: (campaignId: MaybeRef<Campaign>) => [
    ...campaignQueries.details(),
    campaignId,
  ],
  metricsSummary: () =>
    queryOptions({
      queryKey: [...campaignQueries.all(), "campaignMetrics"],
      queryFn: async () => {
        const api = useApi();

        const data = await api<CampaignSummary[]>(`/v1/metrics/summary/`, {
          method: "GET",
        });

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),
  metricsSummaryByCampaign: (campaignId: MaybeRef<string>) =>
    queryOptions({
      queryKey: [...campaignQueries.all(), "campaignMetrics", campaignId],
      queryFn: async () => {
        const api = useApi();

        const data = await api<CampaignSummary>(
          `/v1/metrics/summary/${toValue(campaignId)}`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),
  updates: (campaignId: MaybeRef<Campaign>) =>
    queryOptions({
      queryKey: [...campaignQueries.detail(campaignId), "updates"],
      queryFn: async () => {
        const api = useApi();
        const id = toValue(campaignId);
        const data = await api<UpdatesResponse>(`/v1/goals/${id}/updates`, {
          method: "GET",
        });

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
};

type ListResponse = {
  id: number;
  organization_name: string;
  slug: string;
  dollar_ratio: number;
}[];

type UpdatesResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  campaign_id: Campaign;
  number: number;
  title: string;
  content: string;
  vimeo_id: string;
}[];

type CampaignSummary = {
  campaign_id: number;
  amount: number;
  donors: number;
  impact: number;
  lastMonthAmount: number;
  lastMonthImpact: number;
  lastMonthDonors: number;
};
