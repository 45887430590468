import type { AdditionalCampaignData } from ".";

export default {
  campaignId: 4,
  title: "Clean up the Great Pacific Garbage Patch",
  posterImg: "/images/projects-carousel/turtle.png",
  impactTitle: "OCEAN-BOUND PLASTIC REMOVED",
  impactUnit: "lbs",
  ctaTitle: "BOTTLES WORTH OF OCEAN-BOUND PLASTIC REMOVED",
  impactLabel: "CLEAN UP THE GREAT PACIFIC GARBAGE PATCH",
  dollarImpact: "Removes 1/3 lbs of ocean plastic",
  bgColor: "bg-ocean",
  impactScore: 90,
  icon: "/images/emojis/ocean.png",
  path: "/campaigns/ocean-voyages",
  shortVideoId: "947563401?h=5bbc710dc8",
  shortVideoIdMobile: "947563444?h=dc7ce21ed5",
  featuredReview: {
    name: "Christopher Verlinden",
    photo: "/images/who-we-are-page/advisors/verlinden.jpg",
    title: "U.S Coast Guard CTO, Applied Ocean Sciences",
    quote:
      "Ocean plastic pollution is a serious problem we are only beginning to fully understand. Ocean Voyages Institute has taken an impactful and practical approach to addressing this issue, simultaneously eliminating the pollution and raising global awareness for this critical environmental issue.",
  },
} satisfies AdditionalCampaignData;
