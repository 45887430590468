import type { AdditionalCampaignData } from ".";

export default {
  campaignId: 3,
  title: "Trees That End Hunger & Poverty",
  impactUnit: "trees",
  posterImg: "/images/projects-carousel/children.jpeg",
  impactTitle: "Trees planted",
  ctaTitle: "Plant trees",
  impactLabel: "PLANT TREES THAT END HUNGER & POVERTY",
  dollarImpact: "Plants 2.8 trees",
  bgColor: "bg-nature",
  impactScore: 100,
  icon: "/images/emojis/tree.png",
  path: "/campaigns/trees-for-the-future",
  shortVideoId: "947566193?h=a387efbd45",
  shortVideoIdMobile: "947566271?h=02d560ff0b",
  featuredReview: {
    name: "Tom Chi",
    photo: "/images/who-we-are-page/advisors/chi-2.png",
    title: "Google X (co-founder)",
    quote:
      "Trees for the Future is making agroforestry and self-sufficiency accessible to folks that are really on the margins. This is a remarkable effort, and is also going to help us as the environment becomes more destabilized by climate.",
  },
} satisfies AdditionalCampaignData;
